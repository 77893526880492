export const ADD_NOTI = 'ADD_NOTI';
export const EDIT_NOTI = 'EDIT_NOTI';
export const DELETE_NOTI = 'DELETE_NOTI';

export const TOGGLE_DATETIME = 'TOGGLE_DATETIME';
export const EDIT_DATETIME = 'EDIT_DATETIME';

export const TOGGLE_WALLPAPER = 'TOGGLE_WALLPAPER';

export const TOGGLE_APP = 'TOGGLE_APP';
